<template>
  <base-material-card icon="mdi-clipboard-text" :title="projectName" class="px-5 py-3">
    <div class="project--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
          <label class="col-12 col-lg-3" for="nameField">
            <span>Nama Program Referral</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="nameField"
              v-model="name"
              type="text"
              name="name"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('referralCode') }">
          <label class="col-12 col-lg-3" for="referralCodeField">
            <span>Kode Referral</span>
            <span class="required">*</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="referralCodeField"
              v-model="referralCode"
              type="text"
              name="referralCode"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('referralCode') }}</span>
          </div>
        </div>
        <div class="form--group row" :class="{ 'has-error': validation.hasError('coinBonus') }">
          <label class="col-12 col-lg-3" for="coinBonusField">
            <span>Bonus Koin</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="coinBonusField"
              v-model="coinBonus"
              type="number"
              name="coinBonus"
              outlined
              hide-details
            />
            <span class="val-error">{{ validation.firstError('coinBonus') }}</span>
          </div>
        </div>
        <div class="form--group row">
          <label class="col-12 col-lg-3">Aktif?</label>
          <div class="col-12 col-lg-6">
            <v-btn
              type="button"
              color="success"
              :class="{ 'v-btn--disabled': !isActive }"
              @click="isActive = true"
            >
              {{ $t('general.yes') }}
            </v-btn>
            <v-btn
              type="button"
              color="error"
              :class="{ 'v-btn--disabled': isActive }"
              @click="isActive = false"
            >
              {{ $t('general.no') }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex align-center justify-end">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('referralProgram.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'project-form',
  mixins: [HelperMixin],
  components: {
    PageLoading,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.referralProgram.basePath,
      formType: (state) => state.referralProgram.form.formType,
    }),
    loading: {
      get() {
        return this.$store.state.referralProgram.form.loading;
      },
      set(value) {
        this.$store.commit('referralProgram/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.referralProgram.form.name;
      },
      set(value) {
        this.$store.commit('referralProgram/form/SET_NAME', value);
      },
    },
    referralCode: {
      get() {
        return this.$store.state.referralProgram.form.referralCode;
      },
      set(value) {
        this.$store.commit('referralProgram/form/SET_REFERRAL_CODE', value);
      },
    },
    coinBonus: {
      get() {
        return this.$store.state.referralProgram.form.coinBonus;
      },
      set(value) {
        this.$store.commit('referralProgram/form/SET_COIN_BONUS', value);
      },
    },
    isActive: {
      get() {
        return this.$store.state.referralProgram.form.isActive;
      },
      set(value) {
        this.$store.commit('referralProgram/form/SET_IS_ACTIVE', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.referralProgram.name.required'));
    },
    referralCode(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.referralProgram.referralCode.required'),
      );
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('referralProgram/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('referralProgram.publish.successMsg'),
          'success',
        );
        await this.$router.replace(this.basePath);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
